//
//	Reset
//___________________________________

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin:         0;
    padding:        0;
    border:         0;
    font:           inherit;
    font-size:      100%;
    vertical-align: baseline;
    box-sizing:     border-box;
}

html {
    line-height:      1;
    background-color: #fff;
}

body {
    font-family: 'Open Sans',  sans-serif;
    line-height:             1;
    color:                   #353333;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;

    &.comment-open {
        overflow: hidden;
    }
}

ol, ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing:  0;
}

caption, th, td {
    text-align:     left;
    font-weight:    normal;
    vertical-align: middle;
}

q, blockquote {
    quotes: none;

    &:before,
    &:after {
        content: "";
        content: none;
    }
}

b,
strong {
    font-weight: 700;
}

img {
    max-width: 100%;
    height:    auto;
    border:    none;
    display:   inline-block;
}

a {
    text-decoration: none;

    &:focus, &:hover, &:active {
        outline: 0;
    }
}

::-moz-selection {
    background:  #ed0;
    color:       #444;
    text-shadow: none;
}

::selection {
    background:  #ed0;
    color:       #444;
    text-shadow: none;
}


article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
    display:    block;
    box-sizing: border-box;
}

:focus {
    outline: none;
}

input,
textarea,
button {
    font-family: 'Open Sans',  sans-serif;
}

