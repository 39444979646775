//
//	Theme
//___________________________________

.container {
    @extend %tabl;
    max-width: 1024px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
        max-width: 980px;
    }

    @media screen and (max-width: 1000px) {
        padding: 0 15px;
    }

    @media screen and (max-width: 640px) {
        padding: 0 20px;
    }
    @media screen and (max-width: 375px) {
        padding: 0 15px;
    }
}

[class^=grid-] {
    display: inline-block;
    vertical-align: top;
    margin: 0;

    &.grid-3 {
        width: 290px;

        & + .grid-6 {
            margin-left: 40px;
        }
    }

    &.grid-6 {
        width: calc(100% - 330px);

        & + .grid-3 {
            margin-left: 40px;
        }
    }

    @media screen and (max-width: 801px) {
        &.grid-3 {
            & + .grid-6 {
                margin-left: 25px;
            }
        }

        &.grid-6 {
            width: calc(100% - 315px);

            & + .grid-3 {
                margin-left: 25px;
            }
        }
    }

    @media screen and (max-width: 640px) {
        &.grid-3 {
            display: none;
        }
        &.grid-6 {
            width: 100%;
            margin: 0 !important;
        }
    }

}

[class^=link-] {
    width: 175px;
    height: 50px;
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
    background: url(../img/btn-app.png) 0 0 no-repeat;

    @media screen and (max-width: 801px) {
        margin-right: 15px;
    }

    &.link-google-play {
        background-position: 0 -60px;
        margin-right: 0;
    }

    .footer & {
        margin: 0 15px;

        @media screen and (max-width: 640px) {
            margin: 0;

            &.link-google-play {
                margin-left: 5px;
            }
        }

        @media screen and (max-width: 374px) {
            &.link-google-play {
                margin-left: 0;
            }
        }
    }

    @media screen {
        transition: opacity $time;

        &:hover {
            opacity: .85;
        }
    }

    @media screen and (max-width: 375px) {
        margin-right: 5px;

        &.link-google-play {
            margin-right: 0;
            width: 160px;
            border-radius: 6px;
        }
    }

    @media screen and (max-width: 374px) {
        &.link-google-play {
            width: 175px;
            margin-top: 10px;
            border-radius: 0;
        }
    }

}

.section {
    border-top: 1px solid #a4a5a6;
    padding: 55px 0;
    overflow: hidden;

    &.section-gray {
        background-color: #f5f5f5;
    }

    &.section-yellow {
        position: relative;
        background: rgba(#fde13c, .6);
        border-top: 0;

        &:before {
            @extend %before;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: .3;
            background-image: linear-gradient(0deg, #fde13d 0%, #fee23e 100%);
        }

        .container {
            position: relative;
            z-index: 2;
            min-height: 410px;

            &:after {
                @extend %before;
                bottom: -55px;
                right: -80px;
                width: 505px;
                height: 515px;
                background: url(../img/cover.png) bottom right no-repeat;
            }
        }

        @media screen and (max-width: 1000px) {
            .container {
                &:after {
                    right: -160px;
                }
            }
            .grid-6 {
                width: calc(100% - 300px);
            }
        }

        @media screen and (max-width: 701px) {
            .container {
                &:after {
                    right: -220px;
                }
            }

            .grid-6 {
                width: calc(100% - 240px);
            }
        }

        @media screen and (max-width: 640px) {
            .container {
                min-height: initial;

                &:after {
                    display: none;
                }
            }
            .grid-6 {
                width: 100%;
            }
        }

    }

    @media screen and (max-width: 374px) {
        padding: 35px 0 40px;
    }
}

.section-title {
    font-size: 44px;
    letter-spacing: -1px;
    padding: 30px 0;

    @media screen and (max-width: 801px) {
        padding: 0 0 30px;
    }

    @media screen and (max-width: 755px) {
        font-size: 36px;
    }

    @media screen and (max-width: 374px) {
        font-size: 32px;
    }

    .section-yellow & {
        font-size: 60px;
        color: #131313;

        @media screen and (max-width: 755px) {
            font-size: 48px;
            padding-top: 30px;
        }

        @media screen and (max-width: 640px) {
            padding-top: 0;
        }

        @media screen and (max-width: 374px) {
            font-size: 42px;
        }

    }

    .footer & {
        padding-top: 0;
        font-size: 33px;

        @media screen and (max-width: 755px) {
            font-size: 28px;
        }
    }


}

.section-title-sub {
    font-size: 22px;
    margin-bottom: 60px;

    & + .sub-grid {
        margin-left: 0;
    }

    @media screen and (max-width: 374px) {
        margin-bottom: 40px;
        font-size: 20px;
        line-height: 1.2;
    }

    .section-yellow & {
        margin-bottom: 40px;

        @media screen and (max-width: 374px) {
            margin-bottom: 30px;
        }
    }

}

.footer {
    text-align: center;
    padding-bottom: 70px;

    p + p {
        padding-top: 10px;
    }

    @media screen and (max-width: 374px) {
        padding-bottom: 50px;
    }
}

.sub-grid {
    display: inline-block;
    vertical-align: top;
    width: calc((100% - 50px) / 3);
    margin-left: 25px;

    svg {
        fill: #353334;
        width: 40px;
        height: 40px;

        &.search-icon {
            path {
                transform: scale(1.3) translate(-17px, -12px);
            }
        }
    }

    b {
        display: block;
        font-size: 20px;
        margin: 35px 0;
    }

    p {
        font-weight: 300;
        font-size: 17px;
        line-height: 1.3;
    }

    @media screen and (max-width: 1024px) {
        b {
            font-size: 17px;
        }

        p {
            font-size: 15px;
        }
    }

    @media screen and (max-width: 1000px) {
        display: block;
        width: 100%;
        margin: 0;
        position: relative;
        padding-left: 55px;

        svg {
            position: absolute;
            top: -10px;
            left: 0;
        }

        b {
            font-size: 20px;
            margin: 0 0 20px;
        }

        p {
            margin-bottom: 40px;
            font-size: 17px;
        }
    }

    @media screen and (max-width: 640px) {
        &.sub-grid-last {
            p {
                margin-bottom: 0;
            }
        }
    }
}

.header {
    background-color: #ffcb00;
    height: 62px;

    .logo {
        width: 275px;
        height: 62px;
        display: inline-block;
        vertical-align: top;
    }

    @media screen {
        svg {
            overflow: visible;

            .scale-path {
                transition: transform $time;
                -webkit-backface-visibility: hidden;
            }

            &:hover {
                .scale-path {
                    transform: scale(1.7) translate(-4px, -12px);
                    //transform: scale(1.7);
                }
            }
        }
    }
}