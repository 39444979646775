//
//	Extends
//___________________________________

%clear {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}


%before {
    content: '';
    display: block;
    position: absolute;
}


%tabl {
    display: table;
    width: 100%;
    font-size: 0 !important;
}


%bvh {
    -webkit-backface-visibility: hidden;
}
