//
//	Variable
//___________________________________


$time: .3s;


//
//	Loader
//___________________________________


.loader-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #fff;
    opacity: 1;
    visibility: visible;
    transition: opacity.3s, visibility .3s;
}

.loader {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 2px solid #ffcb00;
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: 20px;
    margin-top: -20px;
    margin-left: -20px;
    z-index: 1;
    animation: cssload-spin 1325ms infinite linear;
}

@keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
    }
}


.load-complete {
    .loader-box {
        opacity: 0;
        visibility: hidden;
    }
}